<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10" lg="8">
                <b-card>
                    <table class="w-full table">
                        <tr>
                            <td>No. Disposal</td>
                            <td>:</td>
                            <th>{{ disposal.no }}</th>
                        </tr>
                        <tr>
                            <td>Jenis Disposal</td>
                            <td>:</td>
                            <th>
                                <b-badge variant="light-info">
                                    {{ jenisDisposal }}
                                </b-badge>
                            </th>
                        </tr>
                        <tr>
                            <td>Tanggal</td>
                            <td>:</td>
                            <th>{{ disposal.tanggal }}</th>
                        </tr>
                        <tr>
                            <td>Aset</td>
                            <td>:</td>
                            <th>
                                <strong v-if="disposal.penyimpanan && disposal.penyimpanan.asset">
                                    {{ disposal.penyimpanan.asset.nama }}
                                </strong>
                                <i class="text-danger" v-else>Asset tidak ditemukan</i>
                            </th>
                        </tr>
                        <tr>
                            <td>Jumlah Disposal</td>
                            <td>:</td>
                            <th>
                                {{ disposal.jumlah }}
                            </th>
                        </tr>
                        <tr>
                            <td>Satuan</td>
                            <td>:</td>
                            <th>
                                <strong v-if="disposal.penyimpanan && disposal.penyimpanan.asset && disposal.penyimpanan.asset.satuan">
                                    {{ disposal.penyimpanan.asset.satuan.satuan }}
                                </strong>
                                <i class="text-danger" v-else>Satuan tidak ditemukan</i>
                            </th>
                        </tr>
                        <tr>
                            <td>Keterangan</td>
                            <td>:</td>
                            <th>{{ disposal.keterangan }}</th>
                        </tr>
                    </table>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BOverlay, BRow, BCol, BCard, BBadge} from 'bootstrap-vue'
export default {
    components: {
        BOverlay, BRow, BCol, BCard, BBadge
    },
    data: () => ({
        loading: false
    }),
    computed: {
        disposal() {
            return this.$store.getters['asset/searchDisposal'](this.$route.params.id)
        },
        jenisDisposal() {
            const jenis = [{key: 1, label: 'Hilang'},{key: 2, label: 'Rusak'},{key: 3, label: 'Ajuan Perbaikan'}]
            const find = jenis.find(item => item.key == this.disposal.jenis)
            return find ? find.label : 'Rusak'
        }
    },
    methods: {
        getGudangName(gudang_id) {
            const find = this.gudangs.find(item => item.id == gudang_id)
            return find ? find.nama_gudang : '-'
        }
    },
    async created() {
        if(!this.disposal) {
            this.$router.push(`${this.$route.meta.parent}`)
        }
    }
}
</script>